export default {
	required(v) {
		return !!(v?.length);
	},
	email(v) {
		return !!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(`${v}`));
	},
	cpf(v) {
		return !!(/[0 - 9]{ 3 } .? [0 - 9]{ 3 } .?[0 - 9]{ 3 } -? [0 - 9]{ 2 }/.test(`${v}`))
	},
	cnpj(v) {
		return !!(/[0 - 9]{ 2 } .? [0 - 9]{ 3 } .?[0 - 9]{ 3 } \/?[0-9]{4}-?[0-9]{2}/.test(`${v}`));
	},
	phone(v) {
		return !!(/\(\d{ 2,} \) \d{ 4,} -\d{ 4 }/.test(`${v}`));
	}
}