<template>
  <el-form v-model="DataBind" :rules="rules" inline-message v-bind="$attrs">
    <template #default>
      <slot name="default"></slot>
    </template>
  </el-form>
</template>
<script>
export default {
  name: "BaseForm",
  data: () => ({
    isLoading: false,
  }),
  props: {
    modelValue: {
      type: [String, Number],
    },
    rules: {
      type: Array,
    },
  },
  methods: {},
  computed: {
    DataBind: {
      set(v) {
        this.$emit("update:modelValue", v);
      },
      get() {
        return this.modelValue;
      },
    },
  },
};
</script>
