<template>
  <el-select v-model="DataBind" v-bind="$attrs">
    <template #default> <slot name="default"></slot> </template
  ></el-select>
</template>
<script>
export default {
  name: "BaseSelect",
  prop: ["modelValue"],
  computed: {
    DataBind: {
      set(v) {
        this.$emit("update:modelValue", v);
      },
      get() {
        return this.modelValue;
      },
    },
  },
};
</script>
