
//import router from "../../ routes";
export default {
    state() {
        return {
            user: null,
            accessRights: {},
            can(key) { return canViewResource(key, this?.$store?.state?.accessRights) }
        }
    },
    mutations: {
        save(state, user) {
            if (user) {
                this.state.user = this?.state?.user ? { ...this.state.user, ...user } : user;
                this.state.accessRights = user?.group?.permissions?.map(p => p.slug)?.reduce((t, p) => {
                    t[p] = true;
                    return t;
                }, {})
            }


            localStorage.setItem('user', this.state.user)

            //router.push('/')
        },
        removeUser() {
            this.state.user = null
            localStorage.setItem('user', null)
        }
    },
    actions: {
        async loadUser({ commit }) {
            await fetch(`${this.state.apiUrl}sessions`, {
                credentials: "include",
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            })
                .then(response => {
                    if (response.status == 200) return response.json()
                    throw response.json()
                })
                .then(
                    json => commit('save', json)
                )
                .catch(
                    () => {
                        commit('removeUser')
                    }
                )
        },
        async authenticate({ commit }, data_auth) {
            return fetch(`${this.state.apiUrl}sessions`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data_auth)
            }).then(async response => {
                if (response.status == 200 || response.status == 302) return response.json()

                throw await response.json()
            }).then(json => {

                document.location.href = '/';
                commit('save', json);

                return json;
            });


        },
        logoffUser({ commit }) {
            fetch(`${this.state.apiUrl}sessions`, {
                credentials: "include",
                method: "DELETE",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
            }).then(response => {
                if (response.status == 200 || response.status == 302) return response.json()
                throw response.text()
            }).then(() => {
                commit('removeUser')
                document.location.href = '/#/entrar';
            });
        }
    },

    getters: {
        isAuthenticated() {
            return localStorage.getItem('user') != "null"
        }
    }
}

function canViewResource(key, accessRights) {
    return key in (accessRights || {})
}
