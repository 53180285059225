<template>
  <router-view />
</template>

<script>
import store from "./store";
export default {
  name: "App",
  store,
};
</script>
<style>
@media print {
  body::-webkit-scrollbar {
    display: none;
  }

  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .el-footer {
    display: none;
  }

  .el-header {
    display: none;
  }
  .el-card__header {
    padding: 5px !important;
  }

  .el-card__header h4 {
    margin: 2px !important;
  }

  .el-table__row {
    page-break-inside: avoid;
  }

  .is-hidden div {
    visibility: visible !important;
  }

  .el-table {
    font-size: 9px !important;
    margin: 5px !important;
  }
}
.el-card {
  width: 100%;
}

tr.unavailable {
  background-color: #fffde7;
}

.is-negative {
  color: red;
}

.is-positive {
  color: blue;
}

tr.problem {
  background-color: #fbe9e7;
}
body {
  min-height: 100%;
}

.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}

.el-input.is-disabled .el-input__inner {
  color: black !important;
}

.el-icon-arrow-up:before,
.el-icon-arrow-down:before {
  font-weight: 1000;
}

.el-table__subheader_date {
  font-weight: 700;
  color: #606266;
  font-size: 16px;
  padding: 8px 0 8px 16px;
  text-align: start;
  display: flex;
  justify-self: start;
}

.el-table__subheader_name {
  text-align: start;
  margin: 0;
  color: #606266;
  font-size: 16px;
  padding: 16px 0 16px 16px;
  background-color: #ebeef5;
}

.el-table .el-table__header-wrapper thead th .cell {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.el-table th.el-table__cell {
  text-align: center;
}

.cell {
  text-align: center;
  word-break: break-word !important;
}

.el-select {
  width: 100%;
}

.centralized-cell {
  text-align: center !important;
}

.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}

.text-grey {
  color: grey;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.p-1 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
</style>
