<template>
  <div :id="id"></div>
</template>
<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridDay from "@fullcalendar/timegrid";

export default {
  name: "base-calendar",
  data: () => ({
    calendar: null,
  }),
  props: {
    id: String,
    options: Object,
    events: Object,
  },
  computed: {
    computedOptions() {
      return {
        ...this.options,
        events: this.events,
        plugins: [dayGridPlugin, interactionPlugin, timeGridDay],
      };
    },
  },
  mounted() {
    const calendarEl = document.getElementById(this.id);
    this.calendar = new Calendar(calendarEl, this.computedOptions);
    this.calendar.render();
    this.$emit("get-calendar", this.calendar);
  },
  watchEffect() {
    if (this.calendar && this.events) {
      this.calendar.pauseRendering();
      this.calendar.resetOptions(this.options);
      this.calendar.resumeRendering();
    }
  },
  unmounted() {
    this.calendar?.destroy();
  },
};
</script>
<style lang="css">
.el-table .el-table__header-wrapper thead th {
  padding-top: 0;
  padding-bottom: 0;
}

.el-table .el-table__header-wrapper thead th .cell {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.el-table .el-table__header-wrapper .sort-caret {
  border: 4px solid transparent;
}

.el-table .el-table__header-wrapper .sort-caret.ascending {
  top: 7px;
}

.el-table .el-table__header-wrapper .ascending .sort-caret.ascending {
  border-bottom-color: #172b4d;
}

.el-table .el-table__header-wrapper .sort-caret.descending {
  bottom: 9px;
}

.el-table .el-table__header-wrapper .descending .sort-caret.descending {
  border-top-color: #172b4d;
}

div.el-table {
  background: transparent;
}

div.el-table tbody td,
div.el-table thead th {
  padding: 1rem;
}

div.el-table .el-table-column--selection .cell {
  min-width: 100px;
  overflow: visible;
  text-overflow: clip;
}

div.el-table .el-table-column--selection .cell .el-checkbox {
  margin-bottom: 0;
}

div.el-table.el-table--enable-row-hover .el-table__body tr:hover > td,
div.el-table .el-table__row,
div.el-table .el-table__row:hover {
  background: transparent;
}

div.el-table .el-table__header .cell,
div.el-table .el-table__row .cell {
  padding: 0;
}

.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.selected:hover,
.flatpickr-calendar .flatpickr-day:hover {
  background: #5e72e4;
  color: #fff;
  box-shadow: none;
  border: none;
}

.flatpickr-calendar {
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px 22px;
  width: 347px;
  color: #525f7f;
}

.flatpickr-calendar .flatpickr-weekday {
  text-align: center;
  font-size: 0.875rem;
  color: #525f7f;
  font-weight: 400;
}

.flatpickr-calendar .flatpickr-day {
  border: none;
}

.flatpickr-calendar .flatpickr-day.today {
  border: 1px solid #5e72e4;
}

.flatpickr-calendar .flatpickr-day.today:hover {
  background: #5e72e4;
  color: #fff;
}

.flatpickr-calendar .flatpickr-day.inRange {
  background: #5e72e4 !important;
  color: #fff;
  box-shadow: -5px 0 0 #5e72e4, 5px 0 0 #5e72e4;
  border: none !important;
}

.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.startRange {
  background: #5e72e4;
}

.flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #5e72e4;
}

.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #5e72e4;
}

.flatpickr-calendar .flatpickr-current-month input.cur-year,
.flatpickr-calendar .flatpickr-current-month span.cur-month {
  padding: 0 10px;
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 500;
}

.flatpickr-calendar .flatpickr-current-month input.cur-year:hover,
.flatpickr-calendar .flatpickr-current-month span.cur-month:hover {
  background: #e9ecef;
  border-radius: 0.375rem;
}

.fc-header-toolbar {
  display: none;
}

.fc-scroller {
  height: auto !important;
}

.fc th {
  padding: 0.75rem 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #8898aa;
  text-transform: uppercase;
}

.fc div.fc-row {
  margin-right: 0;
  border: 0;
}

.fc button .fc-icon {
  top: -5px;
}

.fc .fc-col-header-cell-cushion {
  padding: 0;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: #fb6340;
}

.fc-daygrid-day-top .fc-daygrid-day-number {
  color: #525f7f;
}

.fc-event {
  padding: 0;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  border: 0;
}

.fc-event.fc-h-event {
  background-color: #5e72e4;
}

.fc-event .fc-event-title {
  padding: 0.4rem 0.5rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.fc-event .fc-time {
  float: left;
  background: rgba(0, 0, 0, 0.2);
  padding: 2px 6px;
  margin: 0 0 0 -1px;
}

.fc-event.bg-warning {
  background: #fb6340;
}

.fc-event .fc-event-title-container {
  background: transparent;
}

.fc-view,
.fc-view > table {
  border: 0;
  overflow: hidden;
}

.fc-view > table > tbody > tr .ui-widget-content {
  border-top: 0;
}

.fc-event-title-container {
  background: #5e72e4;
  border-radius: 0.25rem;
}

.fc-daygrid-event-dot {
  border-color: #5e72e4;
}

.fc-body {
  border: 0;
}

.fc-icon {
  font-family: NucleoIcons, sans-serif;
  font-size: 1rem;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
}

.fc-icon:hover {
  color: #5e72e4;
}

.fc-button {
  border: 0;
  background: transparent;
  box-shadow: none;
}

.fc-button:active,
.fc-button:focus {
  outline: 0;
}

.fc-theme-standard {
  z-index: 0;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #eff1f3;
}

.fc-theme-standard .fc-scrollgrid {
  border-left: 0;
  border-color: #eff1f3;
}

.fc-theme-standard .fc-toolbar {
  height: 250px;
  background-color: #fff;
  border-radius: 0.375rem 0.375rem 0 0;
  position: relative;
  margin-bottom: -2px;
  z-index: 2;
}

@media (max-width: 575.98px) {
  .fc-theme-standard .fc-toolbar {
    height: 135px;
  }
}

.fc-theme-standard .fc-daygrid-day-number {
  padding: 0.5rem 1rem;
  width: 100%;
  color: #525f7f;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .fc-theme-standard .fc-daygrid-day-number {
    font-size: 0.875rem;
    font-weight: 600;
    color: #67779d;
  }
}

.fc-theme-standard .fc-day {
  text-align: left;
}

.fc-theme-standard .fc-day a {
  color: #8898aa;
}

.fc-theme-standard .fc-scrollgrid-sync-inner {
  text-align: left;
}

.fc-theme-standard .fc-daygrid-event.fc-event-end {
  margin: 1px 9px;
}

[data-calendar-month] {
  background-size: contain;
  transition: background-image 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  [data-calendar-month] {
    transition: none;
  }
}

[data-calendar-month="0"] {
  background-image: url(/img/calendar/january.jpg);
}

[data-calendar-month="1"] {
  background-image: url(/img/calendar/february.jpg);
}

[data-calendar-month="2"] {
  background-image: url(/img/calendar/march.jpg);
}

[data-calendar-month="3"] {
  background-image: url(/img/calendar/april.jpg);
}

[data-calendar-month="4"] {
  background-image: url(/img/calendar/may.jpg);
}

[data-calendar-month="5"] {
  background-image: url(/img/calendar/june.jpg);
}

[data-calendar-month="6"] {
  background-image: url(/img/calendar/july.jpg);
}

[data-calendar-month="7"] {
  background-image: url(/img/calendar/august.jpg);
}

[data-calendar-month="8"] {
  background-image: url(/img/calendar/september.jpg);
}

[data-calendar-month="9"] {
  background-image: url(/img/calendar/october.jpg);
}

[data-calendar-month="10"] {
  background-image: url(/img/calendar/november.jpg);
}

[data-calendar-month="11"] {
  background-image: url(/img/calendar/december.jpg);
}

.card-calendar .card-header {
  border-bottom: 0;
}

.card-calendar table {
  background: transparent;
}

.card-calendar table tr > td:first-child {
  border-left-width: 0;
}

.card-calendar table tr > td:last-child {
  border-right-width: 0;
}

.widget-calendar {
  position: relative;
  z-index: 0;
}

.widget-calendar td,
.widget-calendar th {
  border-color: transparent;
  text-align: center;
}

.widget-calendar .fc-toolbar {
  margin-top: 1.25rem;
}

.widget-calendar .fc-toolbar h2 {
  font-size: 1rem;
}

.widget-calendar .fc-day-number {
  text-align: center;
  width: 100%;
  padding: 0;
}

.widget-calendar .fc table {
  font-size: 0.875rem;
}

.widget-calendar .fc th {
  padding: 0.75rem 0.5rem;
  font-size: 0.75rem;
}

.widget-calendar .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0;
}

.widget-calendar .fc-scrollgrid {
  border: 0;
}
</style>
