import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store/index.js";

const onlyAuthenticated = async (to, from, next) => {
  if (!store?.state?.user) await store.dispatch("loadUser");

  if (store?.getters?.isAuthenticated) {
    if (
      store?.state?.user?.pending_auth?.length &&
      to.fullPath != "/entrar/aguardando"
    )
      next("/entrar/aguardando");
    else next();
  } else next("/entrar");
};

const onlyNotAuthenticated = (to, from, next) => {
  if (store?.getters?.isAuthenticated) next("/");
  else next();
};

const routes = [
  {
    path: "/",
    redirect: "/inicio",
    component: () => import("../components/layout/dashboard.vue"),
    beforeEnter: onlyAuthenticated,
    children: [
      {
        path: "/inicio",
        name: "Início",
        components: { default: () => import("../views/home.vue") },
      },
      {
        path: "/usuarios",
        name: "Usuários Cadastrados",
        components: { default: () => import("../views/users") },
      },
      {
        path: "/grupos",
        name: "Grupos de Usuários",
        components: { default: () => import("../views/groups.vue") },
      },
      {
        path: "/categorias",
        name: "Categorias de Produtos",
        components: { default: () => import("../views/product-category.vue") },
      },
      {
        path: "/produtos",
        name: "Lista de Produtos",
        components: { default: () => import("../views/products.vue") },
      },
      {
        path: "/produtos/receitas",
        name: "Receita de Carne",
        components: { default: () => import("../views/product_receipt.vue") },
      },
      {
        path: "/produtos-entradas/:entryId?",
        name: "Entrada de Produtos",
        components: { default: () => import("../views/product_entries.vue") },
      },
      {
        path: "/tabela-precos",
        name: "Tabela de Preços",
        components: { default: () => import("../views/prices-table.vue") },
      },
      {
        path: "/empresas",
        name: "Minhas Empresas",
        components: { default: () => import("../views/firms.vue") },
      },
      {
        path: "/carteiras",
        name: "Carteiras Bancárias",
        components: {
          default: () => import("../views/firm_bank_accounts.vue"),
        },
      },
      {
        path: "/boletos",
        name: "Boletos Bancários",
        components: { default: () => import("../views/billets.vue") },
      },
      {
        path: "/centro-custos",
        name: "Centro de Custos",
        components: { default: () => import("../views/costs-center.vue") },
      },
      {
        path: "/contas",
        name: "Contas a Pagar",
        components: { default: () => import("../views/bills.vue") },
      },
      {
        path: "/contas-vencimento-no-dia",
        name: "Relatório de contas por dia",
        components: {
          default: () => import("../views/bills_expire_today_report.vue"),
        },
      },
      {
        path: "/motoristas",
        name: "Motoristas Cadastrados",
        components: { default: () => import("../views/drivers.vue") },
      },
      {
        path: "/veiculos",
        name: "Veículos Cadastrados",
        components: { default: () => import("../views/veihcles.vue") },
      },
      {
        path: "/licitacoes",
        name: "Listagem de Licitações",
        components: { default: () => import("../views/public_sells.vue") },
      },
      {
        path: "/licitacoes/recibos",
        name: "Recibos de Licitações",
        components: {
          default: () => import("../views/public_sells_receipts.vue"),
        },
      },
      {
        path: "/licitacoes/recibos/:receiptId",
        name: "Detalhes do recibo",
        components: {
          default: () => import("../views/public_sells_receipt_details.vue"),
        },
      },
      {
        path: "/fazenda/livro-diario",
        name: "Livro Diário da Fazenda",
        components: {
          default: () => import("../views/farm_dailies.vue"),
        },
      },
      {
        path: "/licitacoes/:publicSellId",
        name: "Detalhes da Licitação",
        components: {
          default: () => import("../views/public_sell_details.vue"),
        },
      },
      {
        path: "/veiculos-consumo",
        name: "Relatório de Consumo dos Veículos",
        components: {
          default: () => import("../views/vehicle_consumption.vue"),
        },
      },
      {
        path: "/veiculos-controle",
        name: "Controle de Veículos",
        components: { default: () => import("../views/vehicle_controls.vue") },
      },
      {
        path: "/veiculos-controle-executar/:uid?",
        name: "Cadastrar Controle do Veículo",
        components: {
          default: () => import("../views/car_control_execution.vue"),
        },
      },
      {
        path: "/cheques/:type",
        name: "Cheques Cadastrados",
        components: { default: () => import("../views/payment-orders.vue") },
      },
      {
        path: "/clientes",
        name: "Clientes Cadastrados",
        components: { default: () => import("../views/clients.vue") },
      },
      {
        path: "/clientes-rotas/:type?",
        name: "Rotas Cadastradas",
        components: { default: () => import("../views/routes.vue") },
      },
      {
        path: "/contatos",
        name: "Contatos Cadastradas",
        components: { default: () => import("../views/contacts.vue") },
      },
      {
        path: "/clientes-margem",
        name: "Tabela de Margens",
        components: { default: () => import("../views/margin-table.vue") },
      },
      {
        path: "/sistema-lixeira",
        name: "Itens Excluídos",
        components: { default: () => import("../views/trash.vue") },
      },
      {
        path: "/ofertas",
        name: "Ofertas cadastradas",
        components: { default: () => import("../views/bids.vue") },
      },
      {
        path: "/ofertas-enviadas",
        name: "Ofertas enviadas",
        components: { default: () => import("../views/bids-sent.vue") },
      },
      {
        path: "/sistema-logs",
        name: "Logs do Usuário",
        components: { default: () => import("../views/logs.vue") },
      },
      {
        path: "/parceiros/:id",
        name: "Detalhes do Parceiro",
        components: { default: () => import("../views/partner-details.vue") },
      },
      {
        path: "/cargos",
        name: "Funções Cadastradas",
        components: { default: () => import("../views/roles.vue") },
      },
      {
        path: "/funcionarios",
        name: "Funcionários Cadastradas",
        components: { default: () => import("../views/employees.vue") },
      },
      {
        path: "/funcionarios/:type",
        name: "Convênios Cadastrados",
        components: { default: () => import("../views/employee_debts.vue") },
      },
      {
        path: "/folha",
        name: "Folha de Pagamentos",
        components: { default: () => import("../views/payrolls.vue") },
      },
      {
        path: "/sistema-configuracoes",
        name: "Configurações do Sistema",
        components: {
          default: () => import("../views/system-preferences.vue"),
        },
      },
      {
        path: "/agenda",
        name: "Minha Agenda",
        components: { default: () => import("../views/agenda.vue") },
      },
      {
        path: "/carcacas",
        name: "Entrada de Carcaças",
        components: { default: () => import("../views/carcass.vue") },
      },
      {
        path: "/vendedores",
        name: "Vendedores",
        components: { default: () => import("../views/sellers.vue") },
      },
      {
        path: "/romaneio",
        name: "Montagem do Romaneio",
        components: { default: () => import("../views/packing_list.vue") },
      },
      {
        path: "/conferencia",
        name: "Conferência do Romaneio",
        components: { default: () => import("../views/checks.vue") },
      },
      {
        path: "/animais-relatorio",
        name: "Relatório de Listagem de Animais",
        components: { default: () => import("../views/animals_report.vue") },
      },
      {
        path: "/animais-relatorio-por-qualidade",
        name: "Relatório de animais por qualidade",
        components: {
          default: () => import("../views/animals_standard_report.vue"),
        },
      },
      {
        path: "/pre-pedido",
        name: "Pré Pedido",
        components: { default: () => import("../views/pre-order.vue") },
      },
      {
        path: "/pre-pedido/licitacao/:publicSellId?",
        name: "Pedido na Licitação",
        components: {
          default: () => import("../views/pre-order_public_sell.vue"),
        },
      },
      {
        path: "/pedidos-finalizados",
        name: "Pedidos Entregues e Finalizados",
        components: {
          default: () => import("../views/pre_orders_completed.vue"),
        },
      },
      {
        path: "/baixar-pagamentos",
        name: "Baixar Pagamentos",
        components: { default: () => import("../views/orders_finalize.vue") },
      },
      {
        path: "/pedidos-diarios",
        name: "Lista de Pedidos do dia",
        components: { default: () => import("../views/orders_daily.vue") },
      },
      {
        path: "/pedidos-relatorio",
        name: "Relatório de Pedidos",
        components: { default: () => import("../views/orders_report.vue") },
      },
      {
        path: "/pedidos-grafico",
        name: "Relatório de Vendas",
        components: { default: () => import("../views/orders_completed_results.vue") },
      },
      {
        path: "/pedidos-cobranca",
        name: "Enviar para Cobrança",
        components: { default: () => import("../views/orders_charge.vue") },
      },
      {
        path: "/pedidos-pagamentos",
        name: "Relatório de Pagamentos de Pedidos",
        components: {
          default: () => import("../views/order_payments_report.vue"),
        },
      },
      {
        path: "/relatorios/pedidos/produtos",
        name: "Relatório de Pedidos por Produtos",
        components: {
          default: () => import("../views/orders_by_products.vue"),
        },
      },
      {
        path: "/relatorio/pedidos/quantidades",
        name: "Relatório Quantidade de Produtos Compra/Venda",
        components: {
          default: () => import("../views/order_quantities_report.vue"),
        },
      },
      {
        path: "/relatorio/pedidos/resumo",
        name: "Resumo de Vendas",
        components: {
          default: () => import("../views/order_resume_report.vue"),
        },
      },
      {
        path: "/relatorio/pedidos/top10",
        name: "TOP 10 de Vendas",
        components: {
          default: () => import("../views/order_top10_report.vue"),
        },
      },
      {
        path: "/relatorio/pedidos/receita",
        name: "Relatório de montagem do gado vendido",
        components: {
          default: () => import("../views/order_receipt_report.vue"),
        },
      },
      {
        path: "/notas-fiscais",
        name: "Notas Fiscais Cadastradas",
        components: { default: () => import("../views/invoices.vue") },
      },
      {
        path: "/pedidos/:orderId/imprimir",
        name: "Imprimir Pedido",
        components: { default: () => import("../views/orders_print.vue") },
      },
      {
        path: "/entregar-pedido",
        name: "Entregar pedidos",
        components: { default: () => import("../views/deliver_order.vue") },
      },
      {
        path: "/pedidos-entrega",
        name: "Pedidos em Entrega",
        components: {
          default: () => import("../views/pre_orders_deliver.vue"),
        },
      },
      {
        path: "/analise-pedidos",
        name: "Análise de Pedidos",
        components: {
          default: () => import("../views/pre_orders_analisys.vue"),
        },
      },
      {
        path: "/conferencia-pedidos",
        name: "Conferência de Pré Pedidos",
        components: {
          default: () => import("../views/pre_orders_checking.vue"),
        },
      },
      {
        path: "/producao",
        name: "Pedidos em Produção",
        components: { default: () => import("../views/productions.vue") },
      },
      {
        path: "/codigos",
        name: "Códigos do Produto",
        components: { default: () => import("../views/product_codes.vue") },
      },
      {
        path: "/abastecimentos",
        name: "Ordens de Abastecimento",
        components: { default: () => import("../views/vehicle_supplies.vue") },
      },
      {
        path: "/fabrica/caixa/cadastro",
        name: "Caixa da Fabrica",
        components: { default: () => import("../views/cashier-close_factory.vue") },
      },
      {
        path: "/fabrica/caixa/listagem",
        name: "Listagem dos caixas cadastrados",
        components: { default: () => import("../views/cashier_close_check_factory.vue") },
      },
      {
        path: "/caixas-fechamento",
        name: "Fechamento de Caixa",
        components: { default: () => import("../views/cashier-close.vue") },
      },
      {
        path: "/caixas-conferencia",
        name: "Conferência de Caixa",
        components: {
          default: () => import("../views/cashier_close_check.vue"),
        },
      },
      {
        path: "/caixas-compras",
        name: "Pedidos de compra",
        components: {
          default: () => import("../views/cashier_requests.vue"),
        },
      },
      {
        path: "/caixas-relatorio",
        name: "Relatório de Caixa",
        components: {
          default: () => import("../views/cashier_close_report.vue"),
        },
      },
      {
        path: "/caixas-resultados",
        name: "Resultados dos Açougues",
        components: {
          default: () => import("../views/cashier_close_results.vue"),
        },
      },
      {
        path: "/romaneio-relatorio",
        name: "Relatório de Listagem de Romaneios",
        components: {
          default: () => import("../views/packing_list_report.vue"),
        },
      },
      {
        path: "/produtos-vendas",
        name: "Vinculação de Vendas aos Produtos",
        components: { default: () => import("../views/product_sells.vue") },
      },
      {
        path: "/contas-listagem",
        name: "Relatório de Listagem de Contas",
        components: { default: () => import("../views/bills_report.vue") },
      },
      {
        path: "/contas-centro-custos",
        name: "Contas por centro de custos",
        components: {
          default: () => import("../views/bills_report_by_cost.vue"),
        },
      },
      {
        path: "/contabilidade-contas",
        name: "Contabilidade",
        components: { default: () => import("../views/accountability.vue") },
      },
      {
        path: "/contabilidade/relatorio",
        name: "Relatório de Contabilidade",
        components: {
          default: () => import("../views/accountability_report.vue"),
        },
      },
      {
        path: "/entradas-notas",
        name: "Notas de entrada",
        components: { default: () => import("../views/purchases.vue") },
      },
      {
        path: "/entradas-relatorio",
        name: "Relatório de entradas por fornecedor",
        components: {
          default: () => import("../views/purchases_providers_report.vue"),
        },
      },
    ],
  },
  {
    path: "/entrar/aguardando",
    name: "Aguardando Aprovação",
    beforeEnter: onlyAuthenticated,
    component: () => import("../views/after-login.vue"),
  },
  {
    path: "/relatorios/romaneio/recibo",
    name: "Recibo Romaneio",
    components: { default: () => import("../views/reports/packing_list.vue") },
  },
  {
    path: "/relatorios/abastecimento/ordem",
    name: "Ordem de Abastecimento",
    components: {
      default: () => import("../views/reports/vehicle_supply_order.vue"),
    },
  },
  {
    path: "/relatorios/caixas",
    name: "Relatório de Fechamento de Caixas",
    components: {
      default: () => import("../views/reports/cashiers_report.vue"),
    },
  },
  {
    path: "/relatorios/romaneios",
    name: "Relatório de Romaneios",
    components: {
      default: () => import("../views/reports/packing_list_report.vue"),
    },
  },
  {
    path: "/relatorios/animais",
    name: "Relatório de Animais",
    components: {
      default: () => import("../views/reports/animals_report.vue"),
    },
  },
  {
    path: "/abastecimento/executar/:uid?",
    name: "Realizar um abastecimento",
    components: {
      default: () => import("../views/car_supply_order_execution.vue"),
    },
  },
  {
    path: "/relatorios/contas",
    name: "Relatório de contas",
    components: { default: () => import("../views/reports/bills.vue") },
  },
  {
    path: "/relatorios/contas-por-centro-custo",
    name: "Relatório de contas por centro de custo",
    components: {
      default: () => import("../views/reports/bills_group_by_cost.vue"),
    },
  },
  {
    path: "/",
    redirect: "/entrar",
    component: () => import("../components/layout/auth.vue"),
    beforeEnter: onlyNotAuthenticated,
    children: [
      {
        path: "/entrar",
        name: "Entrar",
        components: { default: () => import("../views/login.vue") },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
