import BaseButton from "./BaseButton.vue";
import BaseCalendar from "./BaseCalendar.vue";
import BaseDatePicker from "./BaseDatePicker.vue";
import BaseForm from "./BaseForm.vue";
import BaseInput from "./BaseInput.vue";
import BaseFormItem from "./BaseFormItem.vue";
import BaseSelect from "./BaseSelect.vue";
import BaseSelectOption from "./BaseSelectOption.vue";
import BaseDropdown from "./BaseDropdown.vue";

export default {
  "base-button": BaseButton,
  "base-calendar": BaseCalendar,
  "base-date-picker": BaseDatePicker,
  "base-form": BaseForm,
  "base-input": BaseInput,
  "base-form-item": BaseFormItem,
  "base-select": BaseSelect,
  "base-select-option": BaseSelectOption,
  "base-dropdown": BaseDropdown,
};
