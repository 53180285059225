const states = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
]

const payment_days = [
    {
        label: "A vista",
        value: 0,
    },
    {
        label: "3 dias",
        value: 3,
    },
    {
        label: "4 dias",
        value: 4,
    },
    {
        label: "5 dias",
        value: 5,
    },
    {
        label: "7 dias",
        value: 7,
    },
    {
        label: "10 dias",
        value: 10,
    },
    {
        label: "14 dias",
        value: 14,
    },
    {
        label: "15 dias",
        value: 15,
    },
    {
        label: "21 dias",
        value: 21,
    },
    {
        label: "30 dias",
        value: 30,
    },
]

const invoice_config = [
    {
        label: '0',
        value: 0
    },
    {
        label: '1/2',
        value: 0.5
    },
    { label: '1', value: 1 }
]

const payment_methods = {
    cash: "Crediário / Vencimento (no prazo)",
    money: "Dinheiro (a vista)",
    credit: "Cartão de Crédito (a vista)",
    debit: "Cartão de Débito (a vista)",
    billet: "Boleto (no prazo)",
    check: "Cheque (a vista)",
    pix: "PIX (a vista)",
};

export default {
    states, payment_days, invoice_config, payment_methods
}