<template>
  <el-popconfirm
    v-if="ShouldAddPopConfirmToButton"
    @confirm="handleClick($event)"
    :title="confirmText"
    :confirm-button-text="confirmButtonText"
    :cancel-button-text="cancelButtonText"
  >
    <template #reference>
      <el-button
        :disabled="disabled || loading || isLoading"
        :type="type"
        :size="size"
        :icon="icon"
        :plain="plain"
        :loading="loading || isLoading"
        :circle="circle"
        :round="round"
      >
        <slot></slot>
      </el-button>
    </template>
  </el-popconfirm>
  <el-button
    v-else
    :disabled="disabled || loading || isLoading"
    @click="handleClick($event)"
    :type="type"
    :size="size"
    :icon="icon"
    :plain="plain"
    :loading="loading || isLoading"
    :circle="circle"
    :round="round"
  >
    {{ text }}
  </el-button>
</template>
<script>
export default {
  name: "BaseButton",
  data() {
    return {
      isLoading: this.loading,
    };
  },
  computed: {
    ShouldAddPopConfirmToButton() {
      return this.type === "danger";
    },
  },
  props: {
    type: {
      type: String,
      default: "primary",
    },
    text: {
      type: String,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    icon: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    circle: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: "Tem certeza?",
    },
    confirmButtonText: {
      type: String,
      default: "Sim",
    },
    cancelButtonText: {
      type: String,
      default: "Não",
    },
  },
  methods: {
    async handleClick() {
      this.isLoading = true;
      try {
        if (this.onClickIsFunc()) await this.onClick();
      } finally {
        this.isLoading = false;
      }
    },
    onClickIsFunc() {
      return typeof this.onClick === "function";
    },
  },
};
</script>
