import { createApp } from "vue";
import App from "./App.vue";
import installElementPlus from "./plugins/element";
import router from "./routes/index";
import store from "./store/index.js";
import Maska from "maska";
import Rollbar from "rollbar";
import BaseComponents from "./components/index";
const rollbar = new Rollbar({
  accessToken: "241d8eef5e1c4662a82ddd07c8b583cd",
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === "production",
  source_map_enabled: true,
  environment: process.env.NODE_ENV,
  payload: {
    client: {
      javascript: {
        code_version: "1.0",
      },
    },
  },
});
const app = createApp(App);
if (process.env.NODE_ENV === "production")
  app.config.errorHandler = (err, vm, info) => {
    const username = vm.$store.state?.user?.name;
    rollbar.error(err, info, username);

    throw err;
  };

app.use(router);
app.use(store);
app.use(Maska);
Object.entries(BaseComponents).map(([key, component]) =>
  app.component(key, component)
);
installElementPlus(app);
app.mount("#app");
