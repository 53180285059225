import { createStore } from "vuex"
import constants from "./constants";
import auth from "./modules/auth";
window['NODE_ENV'] = process.env.NODE_ENV || 'development'
const store = createStore({
    state: {
        apiUrl: window.NODE_ENV === "production" ? window.location.protocol + '//api.' + window.location.hostname + '/' : `http://${window.location.hostname}:3333/`,
        constants,
        searchNameQuery: '',
        user: null
    },
    mutations: {
        'update-search': function (state, payload) {
            state.searchNameQuery = payload
        }
    },
    modules: {
        auth
    }
})




export default store