<template>
  <el-input
    size="medium"
    v-if="mask"
    :placeholder="hint"
    v-model.lazy="MaskedValue"
    :showPassword="showInput"
    :type="type"
    ref="inputWithMask"
    v-maska="mask"
    clearable
    label="text"
    :disabled="disabled"
  >
    <template #prepend v-if="label">
      <el-col :md="8">{{ label }}</el-col>
    </template>
    <template #append v-if="$slots.append">
      <slot name="append"></slot>
    </template>
    <template #prefix v-if="$slots.prefix">
      <slot name="prefix"></slot>
    </template>
  </el-input>
  <el-input
    size="medium"
    v-else-if="type != 'money'"
    :placeholder="hint"
    v-model="rawValue"
    :showPassword="showInput"
    :type="type"
    clearable
    label="text"
    :disabled="disabled"
  >
    <template #prepend v-if="label">
      <el-col :md="8">{{ label }}</el-col>
    </template>
    <template #append v-if="$slots.append">
      <slot name="append"></slot>
    </template>
    <template #prefix v-if="$slots.prefix">
      <slot name="prefix"></slot>
    </template>
  </el-input>
  <el-input
    size="medium"
    v-else
    type="tel"
    :placeholder="hint"
    v-model.lazy="MoneyValue"
    clearable
    label="text"
    :disabled="disabled"
  >
    <template #prepend v-if="label">
      <el-col :md="8">{{ label }}</el-col>
    </template>
    <template #append v-if="$slots.append">
      <slot name="append"></slot>
    </template>
    <template #prefix v-if="$slots.prefix">
      <slot name="prefix"></slot>
    </template>
  </el-input>
</template>
<script>
import { mask, tokens } from "maska";
import Validators from "../utils/validators";
export default {
  name: "BaseInput",
  data() {
    return {
      rawValue: this.modelValue,
      moneyFormatter: new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: this.minimumFractionDigits ?? 2,
      }),
    };
  },
  emits: ["validation", "update:modelValue"],
  watch: {
    modelValue(v) {
      this.rawValue = v;
    },
    rawValue(v) {
      this.$emit("update:modelValue", v);
    },
  },
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: [String, Boolean, Object, Number],
    },
    mask: {
      type: [String, Array],
    },
    hint: {
      type: String,
    },
    type: {
      type: String,
    },
    showInput: {
      type: Boolean,
    },
    validationRules: {
      type: String,
      default: "",
    },
    isNegative: {
      type: Boolean,
      default: false,
    },
    minimumFractionDigits: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
    }
  },
  computed: {
    MaskedValue: {
      get() {
        if (this.mask)
          return mask(`${this.rawValue}`, this.mask, {
            ...tokens,
          });
        return this.rawValue;
      },
      set(v) {
        this.onMaska(v);
        this.validateInput(v);
        //this.$emit("update:modelValue", v);
      },
    },
    MoneyValue: {
      get() {
        return this.moneyFormatter.format(
          Number(this.rawValue) || 0 * (this.isNegative ? -1 : 1)
        );
      },
      set(v) {
        this.$emit("update:modelValue", this.transformMoneyToNumber(v));
        this.validateInput(this.transformMoneyToNumber(v));
      },
    },
  },
  methods: {
    validateInput(value) {
      if (this.validationRules) {
        const rules = this.validationRules?.split("|");

        this.$emit(
          "validation",
          rules?.every((r) => (r in Validators ? Validators[r](value) : true))
        );
      }
    },
    limitValueOnMax(v, max){
      return Math.min(v, max);
    },
    onMaska(v) {
      this.$emit("update:modelValue", v.replace(/[^0-9]/g, ""));
    },
    transformMoneyToNumber(s) {
      const number = s
        ? (
            (parseFloat(
              s.replace("-", "").replace(/[.]/g, "").replace(/[,]/g, "")
            ) *
              (this.isNegative ? -1 : 1)) /
            Math.pow(10, this.minimumFractionDigits)
          ).toFixed(this.minimumFractionDigits)
        : s;

      if(this.max)
        return this.limitValueOnMax(number, this.max);
      else return number;
    },
  },
};
</script>
<style scoped>
.el-input {
  margin-top: 5px;
}
</style>