<template>
  <el-date-picker
    :disabled="disabled"
    v-model="DataBind"
    :placeholder="placeholder"
    type="datetime"
    size="medium"
    :format="format"
    v-bind="$attrs"
  ></el-date-picker>
</template>
<script>
export default {
  name: "BaseDatePicker",
  props: {
    modelValue: {
      type: Date,
    },
    disabled: {
      type: Boolean,
    },
    format: {
      type: String,
      default: () => "DD/MM/YYYY",
    },
    placeholder: {
      type: String,
    },
  },
  computed: {
    DataBind: {
      set(v) {
        this.$emit("update:modelValue", v);
      },
      get() {
        return this.modelValue;
      },
    },
  },
};
</script>
