<template>
  <el-dropdown
    :type="type"
    @command="dispatchCommand"
    trigger="click"
    :disabled="disabled"
  >
    <el-button :type="type" :disabled="disabled">
      {{ labels[selected] }}
      <el-icon class="el-icon-arrow-down" />
    </el-button>
    <template #dropdown>
      <el-dropdown-item v-for="(e, id) of labels" :key="e + id" :command="id">{{
        labels[id]
      }}</el-dropdown-item>
    </template>
  </el-dropdown>
</template>
<script>
export default {
  name: "BaseDropdown",
  emits: ["command"],
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.selectedCommand)
      this.selected = this.commands.indexOf(this.selectedCommand);
    else this.selected = 0;
  },
  methods: {
    dispatchCommand(v) {
      this.selected = v;
      this.$emit("command", this.commands[v]);
    },
  },
  props: {
    size: {
      type: String,
      default: "small",
    },
    selectedCommand: {
      type: String,
      default: "",
    },
    labels: {
      type: Array,
      default: () => [],
    },
    commands: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "primary",
    },
    disabled:{
      type: Boolean,
      default: false
    },
  },
};
</script>
